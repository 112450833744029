import { DownloadOutlined } from '@ant-design/icons';
import { message, Table } from 'antd';
import axios from 'axios';
import ExcelJS from 'exceljs';
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as util from "util";
import logo from '../../assets/legalpanel-dark.png';
import useFormatter from '../../hooks/useFormatter';
import innerText from '../../util/innerText';
import ActionButton from '../action-button';
import styles from './_styles.less';

function MiniTable({ columns, dataSource, loading, rowSelection, scroll, style, onRow, ...props }, ref) {
  const { t } = useTranslation();
  const divRef = useRef(null);
  const history = useHistory();
  const formatter = useFormatter();
  const [pageSize, setPageSize] = useState(5);
  const [scrollY, setScrollY] = useState(scroll?.y);

  useImperativeHandle(ref, () => ({
    download: async () => {
      const logoBuffer = (await axios.get(logo, { baseURL: null, responseType: 'arraybuffer' })).data;

      let wbNome = [document.title];
      let wsNome;
      const pathname = history.location.pathname;
      const index = pathname.lastIndexOf('/');
      if (index >= 0) {
        wsNome = pathname.substring(index + 1);
        wbNome.push(wsNome);
      }
      wbNome.push(new Date().toLocaleString());
      wbNome = wbNome.join(' - ');

      const wb = new ExcelJS.Workbook();
      const logoImageId = wb.addImage({
        buffer: logoBuffer,
        extension: 'png',
      });

      const ws = wb.addWorksheet(wsNome);
      ws.addImage(logoImageId, {
        tl: { col: 0.25, row: 0.5 },
        editAs: 'absolute',
        ext: { width: 120, height: 24 },
        hyperlinks: {
          hyperlink: location.origin,
          tooltip: location.origin,
        },
      });
      const headerRow = ws.getRow(1);
      headerRow.height = 28;
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF262626' },
      };

      const cols = columns.map(column => ({
        ...column,
        width: column.title?.length ?? 1,
        getCellValue(row) {
          let width = 8;
          let text;
          if (column.dataIndex instanceof Array)
            text = column.dataIndex.reduce((pv, cv) => pv[cv], row);
          else
            text = row[column.dataIndex];
          if (typeof column.render === 'function')
            text = column.render(text);
          text = innerText(text);
          if (column.width)
            width += Math.ceil(column.width / 15);
          else if (text?.length)
            width += text.length;
          this.width = Math.max(this.width, width);
          return text;
        },
      }));
      ws.addTable({
        name: wsNome,
        ref: 'A2',
        headerRow: true,
        style: {
          theme: 'TableStyleLight1',
          showRowStripes: true,
        },
        columns: cols.map(column => ({
          name: column.title,
          filterButton: true,
        })),
        rows: dataSource.map(row => cols.map(column => {
          const value = column.getCellValue(row);
          try {
            if (/^-?[\d.,]+$/.test(value)) {
              const number = formatter.parseNumber(value);
              if (isFinite(number))
                return number;
            }
          } catch (e) {
          }
          return value;
        })),
      });

      for (let i = 0; i < ws.columns.length; i++) {
        ws.columns[i].width = cols[i].width + 8;
      }

      const buffer = await wb.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const a = document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('href', window.URL.createObjectURL(blob));
      a.setAttribute('download', `${wbNome}.xlsx`);
      a.click();

      await new Promise(resolve => setTimeout(resolve, 5000));
    },
  }), [columns, dataSource, loading]);

  useLayoutEffect(() => {
    function resize() {
      setPageSize(Math.max(Math.floor((divRef.current.clientHeight - 60) / 26.88), 5));
      if (scroll?.y === 'auto') setScrollY(0);
    }

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [divRef.current, scroll]);

  useEffect(() => {
    if (scroll?.y === 'auto' && scrollY === 0) {
      const thead = divRef.current.querySelector('.ant-table-thead');
      setScrollY(divRef.current.clientHeight - thead.clientHeight - 1);
    }
  }, [scrollY]);

  const handleRow = (record, rowIndex) => {
    let fn;
    if (typeof onRow === 'function')
      fn = onRow(record, rowIndex);
    return {
      onClick: event => {
        if (typeof fn?.onClick === 'function')
          fn?.onClick(event);
        else
          event.currentTarget.classList.toggle('focused');
      }, // click row
      onDoubleClick: event => {
        if (typeof fn?.onDoubleClick === 'function')
          fn?.onDoubleClick(event);
      }, // double click row
      onContextMenu: event => {
        if (typeof fn?.onContextMenu === 'function')
          fn?.onContextMenu(event);
      }, // right button click row
      onMouseEnter: event => {
        if (typeof fn?.onMouseEnter === 'function')
          fn?.onMouseEnter(event);
      }, // mouse enter row
      onMouseLeave: event => {
        if (typeof fn?.onMouseLeave === 'function')
          fn?.onMouseLeave(event);
      }, // mouse leave row
    }
  }

  const showTotal = (total, range) => {
    let message = total === 1 ?
      util.format(t('exibindo %d item'), total) :
      util.format(t('exibindo %d-%d de %d itens'), range[0], range[1], total);
    const selecionados = rowSelection?.selectedRowKeys?.length ?? 0;
    if (selecionados === 1)
      message = util.format(t('%d selecionado, %s'), selecionados, message);
    else if (selecionados > 1)
      message = util.format(t('%d selecionados, %s'), selecionados, message);
    return message;
  };

  return (
    <div ref={divRef} className={styles['mini-table']} style={style}>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          pageSize,
          showTotal,
          showSizeChanger: false,
        }}
        rowKey="id"
        rowSelection={rowSelection}
        scroll={{ x: scroll?.x ?? true, y: scrollY }}
        onRow={handleRow}
        {...props}
      />
    </div>
  );
}

function DownloadButton({ miniTableRef, disabled, ...props }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    miniTableRef.current.download()
      .catch(err => message.error(err))
      .finally(() => setLoading(false));
  };
  return (
    <ActionButton
      disabled={disabled}
      noTitle
      title={t('Baixar Dados')}
      icon={<DownloadOutlined/>}
      loading={loading}
      onClick={handleClick}
      {...props}
    />
  );
}

MiniTable = forwardRef(MiniTable);
MiniTable.DownloadButton = DownloadButton;

export default MiniTable;

