import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from "../../../../hooks/useFormatter";
import styles from '../_styles.less';
import { CommentOutlined, LockOutlined, MinusSquareOutlined, PlusSquareOutlined, RobotOutlined, UnlockOutlined, UserOutlined } from "@ant-design/icons";
import Brand from "../../../../components/brand";
import { Tooltip } from "antd";

export default function ConsultivoConsultaLogsTable({ dataSource, loading, onChange }) {
  return (
    <div className={styles['consulta-logs']}>
      {dataSource.map(log => <Log key={log.id} log={log} loading={loading} onChange={onChange}/>)}
    </div>
  );
}

function Log({ log, loading, onChange }) {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();
  const [exibirDetalhe, setExibirDetalhe] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    onChange({ ...log, publico: !log.publico });
  }

  const publicoTitle = log.publico ? t('Público') : t('Privado');
  const publicoIcon = log.publico ? <UnlockOutlined className="primary"/> : <LockOutlined className="error"/>;

  const publico =
    onChange
      ? <Tooltip
        overlayClassName='ws-pre-line'
        title={publicoTitle + '.\n' + t('Clique para alterar.')}
      >
        <div className={styles['botao']} onClick={handleClick}>
          {publicoIcon}
        </div>
      </Tooltip>
      : <Tooltip title={publicoTitle}>
        {publicoIcon}
      </Tooltip>

  const { texto, textoDetalhado } = useMemo(() => {
    let texto = log.texto;
    let textoDetalhado;
    const index = log.texto.indexOf('\n');
    if (index >= 0) {
      texto = log.texto.slice(0, index);
      textoDetalhado = log.texto.slice(index + 1);
    }
    return { texto, textoDetalhado };
  }, [log.texto]);

  return (
    <div className={styles['log']} key={log.id}>
      <div className={styles['conteudo']}>
        <div className={styles['acoes']}>
          {publico}
          {!log.pessoal &&
            <Tooltip title={t('Comentário gerado pelo sistema.')}>
              <RobotOutlined className="inactive"/>
            </Tooltip>
          }
          {log.pessoal &&
            <Tooltip title={t('Comentário criado pelo usuário.')}>
              <CommentOutlined/>
            </Tooltip>
          }
        </div>
        <div>{texto}</div>
        <div className="grow"/>
        <div className={styles['autor']}>
          <div>{t('por')}</div>
          {!log.usuario &&
            <div className={styles['icone-sistema']}>
              <Brand iconOnly height={12.8}/>
            </div>
          }
          {!!log.usuario &&
            <div className={styles['icone-pessoal']}>
              <UserOutlined/>
            </div>
          }
          <div>
            <strong>
              {!log.usuario && 'Legal Panel'}
              {!!log.usuario && log.usuario?.nome}
            </strong>
          </div>
          <div>{t('em')}</div>
          <div>{formatDateTime(log.createdAt)}</div>
        </div>
        <div className={styles['acoes']}>
          {!exibirDetalhe && textoDetalhado &&
            <Tooltip title={t('Exibir texto detalhado.')} placement="right">
              <div className={styles['botao']} onClick={e => setExibirDetalhe(true)}>
                <PlusSquareOutlined/>
              </div>
            </Tooltip>}
          {exibirDetalhe && textoDetalhado &&
            <Tooltip title={t('Esconder texto detalhado.')} placement="right">
              <div className={styles['botao']} onClick={e => setExibirDetalhe(false)}>
                <MinusSquareOutlined/>
              </div>
            </Tooltip>}
        </div>
      </div>
      {exibirDetalhe && textoDetalhado && (
        <div className={styles['detalhe']}>
          <div className={styles['texto']}>
            {textoDetalhado}
          </div>
        </div>
      )}
    </div>
  )
}

ConsultivoConsultaLogsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func
};
