import { Col, Row, Spin } from "antd";
import React from 'react';
import ActionBar from "../../../../components/action-bar";
import ActionButton from "../../../../components/action-button";
import { useSelector } from "react-redux";
import { cadastroSelector, fetchCadastro, hasPermissaoSelector } from "../../../../store/consultivoConsulta";
import useLoading from "../../../../hooks/useLoading";
import useFormatter from "../../../../hooks/useFormatter";
import { useHistory, useRouteMatch } from "react-router";
import TextItem from "../../../../components/text-item";
import { useTranslation } from "react-i18next";
import useConsultivo from "../../../../hooks/useConsultivo";
import { Consulta, findById } from "../../../../constants";

export default function ConsultivoConsultaCadastroDetailPage() {
  const { t } = useTranslation();
  const { getAreaCaminho } = useConsultivo();
  const { formatBoolean, formatDate, formatNumber } = useFormatter();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.CADASTRO_ALTERAR.id));
  const cadastro = useSelector(cadastroSelector);
  const loading = useLoading(fetchCadastro.typePrefix);
  const { formatDateTime } = useFormatter();
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;

  const handleEdit = () => {
    history.replace(`/consultivo/consultas/${consultaId}/cadastro/edit`);
  };

  return (
    <Spin spinning={loading}>
      {isEditable &&
        <ActionBar>
          <ActionButton.Edit onClick={handleEdit}/>
        </ActionBar>
      }
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Empresa')}>{cadastro?.empresa?.nome}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Status')}>{t(findById(Consulta.Status, cadastro?.status)?.label)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Unidade')}>{cadastro?.unidade?.apelido}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Abertura em')}>{formatDate(cadastro?.dataAbertura)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Área de Atuação')}>{getAreaCaminho(cadastro?.area?.id)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Id Externo')}>{cadastro?.idExterno}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Tipo de Trabalho')}>{cadastro?.tipoTrabalho?.descricao}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Prioridade')}>{t(findById(Consulta.Prioridade, cadastro?.prioridade)?.label)}</TextItem></Col>
        <Col flex="200px">
          <TextItem label={t('Tempo Estimado')}>
            {cadastro?.tempoEstimado ? formatNumber(cadastro?.tempoEstimado) + ' ' + t('dia(s)') : ''}
          </TextItem>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Assunto')}>{cadastro?.assunto?.descricao}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Início em')}>{formatDate(cadastro?.dataInicio)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Conclusão em')}>{formatDate(cadastro?.dataConclusao)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Título')}>{cadastro?.titulo}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Sigilo')}>{formatBoolean(cadastro?.sigilo)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Descrição')}>{cadastro?.descricao}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Solicitante')}>{cadastro?.usuarioSolicitante?.nome}</TextItem></Col>
        <Col flex="400px"><TextItem label={t('Criador')}>{cadastro?.usuarioCriador?.nome}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Controle')}>{cadastro?.usuarioControle?.nome}</TextItem></Col>
        <Col flex="400px"><TextItem label={t('Responsável')}>{cadastro?.usuarioResponsavel?.nome}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('E-mails Extras')}>{cadastro?.emailsExtras?.map(it => <div>{it}</div>)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(cadastro?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(cadastro?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{cadastro?.id}</TextItem></Col>
      </Row>
    </Spin>
  )
}
