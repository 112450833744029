import { Checkbox, Spin } from "antd";
import FlexCard from "../../../components/flex-card";
import styles from "./_styles.less";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Consulta } from "../../../constants";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { filtrar, filtroSelector } from "../../../store/consultivoDashboard";

export default function FiltroStatusCard({}) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const { statusIds } = useSelector(filtroSelector);

  const options = useMemo(() =>
      Object
        .values(Consulta.Status)
        .map(status => ({ label: t(status.label), value: status.id })),
    []);

  const handleChange = useCallback(statusIds => dispatch(filtrar({ statusIds })), []);

  return (
    <Spin spinning={false}>
      <FlexCard bordered={false}>
        <div className={styles['filtro-status']}>
          <h1>{t('Status')}</h1>
          <Checkbox.Group
            className={styles['checkbox-vertical']}
            options={options}
            value={statusIds}
            onChange={handleChange}
          />
        </div>
      </FlexCard>
    </Spin>
  );
}
