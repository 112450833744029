import { Menu, Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useLoading from '../../../../hooks/useLoading';
import { cabecalhoSelector, fetchCabecalho, hasPermissaoSelector } from '../../../../store/consultivoConsulta';
import styles from '../_styles.less';
import { useRouteMatch } from "react-router";
import ActionLink from "../../../../components/action-link";
import CabecalhoDrawer from "./_CabecalhoDrawer";
import { Consulta } from "../../../../constants";

export default function ConsultaCabecalho() {
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.CABECALHO_ALTERAR.id));
  const cabecalho = useSelector(cabecalhoSelector);
  const loading = useLoading(fetchCabecalho.typePrefix);
  const match = useRouteMatch();
  const [cabecalhoDrawerVisible, setCabecalhoDrawerVisible] = useState(false);

  const handleEditClick = () => {
    setCabecalhoDrawerVisible(true);
  };

  const handleCabecalhoDrawerClose = () => {
    setCabecalhoDrawerVisible(false);
  };

  const buttons = [];
  if (isEditable) {
    buttons.push(
      <Menu.Item key="edit">
        <ActionLink.Edit noTitle onClick={handleEditClick}/>
      </Menu.Item>,
    );
  }

  if (loading) {
    return (
      <Menu className={styles['cabecalho-menu']} theme="dark" mode="horizontal" selectable={false}>
        <Menu.Item>
          <Spin/>
        </Menu.Item>
      </Menu>);
  }

  return (
    <>
      <Menu className={styles['cabecalho-menu']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
        <Menu.Item>
          {cabecalho?.titulo}
        </Menu.Item>
      </Menu>
      <Menu className={styles['cabecalho-menu-buttons']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
        {buttons}
      </Menu>
      <CabecalhoDrawer visible={cabecalhoDrawerVisible} onClose={handleCabecalhoDrawerClose}/>
    </>
  );
}
