import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { fetchLicenca, isEditableSelector, licencaSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import { EmpresaLicenca, findByIdAndMap } from "../../../../constants";
import useModulo from "../../../../hooks/useModulo";

const { Aviso } = EmpresaLicenca;

export default function EmpresaLicencaDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const licenca = useSelector(licencaSelector);
  const { formatCurrency, formatDate, formatDateTime, formatBoolean } = useFormatter();
  const loading = useLoading(fetchLicenca.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/licencas/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/licencas`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/licencas/${id}/`, `logs_/empresas/${empresaId}/licencas/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Unidade')}>{licenca?.unidade ? licenca.unidade.id + ' - ' + licenca.unidade.apelido : ''}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"> <TextItem label={t('Nome')}>{licenca?.nome}</TextItem> </Col>
        <Col flex="200px"> <TextItem label={t('Órgão Emissor')}>{licenca?.orgaoEmissor}</TextItem> </Col>
        <Col flex="200px"> <TextItem label={t('Custo Total (R$)')}>{formatCurrency(licenca?.custoTotal)}</TextItem> </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label={t('Data Inicial')}>{formatDate(licenca?.dataInicial)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Prazo Indeterminado')}>{formatBoolean(licenca?.indeterminado)}</TextItem></Col>
        <Col flex="200px">
          {licenca?.indeterminado === false &&
            <TextItem label={t('Data Final')}>{formatDate(licenca?.dataFinal)}</TextItem>
          }
        </Col>
        <Col flex="200px"><TextItem label={t('Controle de Prazo')}>{findByIdAndMap(Aviso, licenca?.aviso, aviso => t(aviso.label))}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"> <TextItem label={t('Site')}>{licenca?.site}</TextItem> </Col>
        <Col flex="200px"> <TextItem label={t('Login')}>{licenca?.login}</TextItem> </Col>
        <Col flex="200px"> <TextItem label={t('Senha')}>{licenca?.senha}</TextItem> </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Conteúdo')}>{licenca?.conteudo}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Anexo')} value={licenca?.anexo}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Comprovante de Pagamento')} value={licenca?.anexoComprovantePagamento}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{licenca?.obs}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(licenca?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(licenca?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
      </Row>
    </Spin>
  );
}
