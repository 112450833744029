import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import { compareStrings } from '../../../../util/string';
import useFormatter from "../../../../hooks/useFormatter";
import ActionButton from "../../../../components/action-button";
import { CheckOutlined } from "@ant-design/icons";
import { Form, Popover } from "antd";
import InputDate from "../../../../components/input-date";
import ActionBar from "../../../../components/action-bar";

export default function ConsultivoConsultaFasesTable({ dataSource, disabled, loading, miniTableRef, onConcluir, title }) {
  const { t } = useTranslation();
  const { formatDate } = useFormatter();

  const columns = [
    {
      title: t('Descrição'),
      dataIndex: 'descricao',
      key: 'descricao',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
    },
    {
      title: t('Conclusão Em'),
      dataIndex: 'dataConclusao',
      key: 'dataConclusao',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataConclusao, b.dataConclusao),
      render: (text, record) => {
        if (disabled || record.dataConclusao)
          return formatDate(record.dataConclusao);
        return <ActionColumn fase={record} onConcluir={onConcluir}/>
      }
    }
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
    />
  );
}

ConsultivoConsultaFasesTable.propTypes = {
  dataSource: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onConcluir: PropTypes.func,
  title: PropTypes.func,
};

function ActionColumn({ fase, onConcluir }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open)
      document.querySelector('#dataConclusao').focus();
  }, [open]);

  const handleFinish = useCallback(values => {
    if (onConcluir) {
      setLoading(true);
      return Promise
        .resolve(onConcluir({ ...fase, ...values }, { form }))
        .finally(() => setLoading(false));
    }
    return Promise.resolve();
  }, [fase]);

  return (
    <Popover
      open={open}
      onOpenChange={open => setOpen(open)}
      trigger="click"
      content={
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item
            name="dataConclusao"
            label={t('Data de Conclusão')}
            initialValue={new Date()}
          >
            <InputDate disabled={loading}/>
          </Form.Item>
          <ActionBar>
            <ActionButton
              primary
              icon={<CheckOutlined/>}
              loading={loading}
              onClick={() => form.submit()}
            >
              {t('Concluir')}
            </ActionButton>
            <ActionButton.Cancel
              disabled={loading}
              onClick={() => setOpen(false)}
            />
          </ActionBar>
        </Form>
      }
    >
      <ActionButton primary icon={<CheckOutlined/>}>
        {t('Concluir')}
      </ActionButton>
    </Popover>
  )
}
