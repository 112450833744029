import { Col, Spin } from 'antd';
import React, { useRef } from 'react';
import { getElementAtEvent } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import useLoading from "../../../hooks/useLoading";
import { consultasPorStatusSelector } from "../../../store/consultivoDashboard";
import { useSelector } from "react-redux";
import StatusCard from "./_StatusCard";

export default function ConsultasPorStatusCardGroup() {
  const chartRef = useRef(null);
  const { t } = useTranslation();
  const loading = useLoading(consultasPorStatusSelector.typePrefix);
  const { cadastro, aberto, emTrabalho, aguardandoDocumentos, pausado, emAtraso, total } = useSelector(consultasPorStatusSelector);

  const handleClick = e => {
    const { current: chart } = chartRef;
    if (chart) {
      const element = getElementAtEvent(chart, e);
      if (element.length) {

      }
    }
  };

  return (
    <>
      <Col span={24} md={8}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-primary"
            count={cadastro}
            loading={loading}
            title={t('Cadastro')}
            total={total}
            onClick={handleClick}
          />
        </Spin>
      </Col>
      <Col span={24} md={8}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-warning"
            count={aberto}
            loading={loading}
            title={t('Aberto')}
            total={total}
            onClick={handleClick}
          />
        </Spin>
      </Col>
      <Col span={24} md={8}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-error"
            count={emAtraso}
            loading={loading}
            title={t('Em Atraso')}
            total={total}
            onClick={handleClick}
          />
        </Spin>
      </Col>
      <Col span={24} md={8}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-success"
            count={emTrabalho}
            loading={loading}
            title={t('Em Trabalho')}
            total={total}
            onClick={handleClick}
          />
        </Spin>
      </Col>
      <Col span={24} md={8}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-inactive"
            count={aguardandoDocumentos}
            loading={loading}
            title={t('Aguardando Documentos')}
            total={total}
            onClick={handleClick}
          />
        </Spin>
      </Col>
      <Col span={24} md={8}>
        <Spin spinning={loading}>
          <StatusCard
            className="background-inactive"
            count={pausado}
            loading={loading}
            title={t('Pausado')}
            total={total}
            onClick={handleClick}
          />
        </Spin>
      </Col>
    </>);
}
