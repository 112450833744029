import imageSocietario from "../assets/images/societario-small.png";
import imageRegulatorio from "../assets/images/regulatorio-small.png";
import imageWorkflow from "../assets/images/workflow-small.png";
import imageConsultivo from "../assets/images/consultivo-small.png";
import imageCompliance from "../assets/images/compliance-small.png";
import imageDataAnalytics from "../assets/images/data-analytics-small.png";
import imageSocietarioConheca from "../assets/images/societario-conheca.png";
import imageRegulatorioConheca from "../assets/images/regulatorio-conheca.png";
import imageWorkflowConheca from "../assets/images/workflow-conheca.png";
import imageConsultivoConheca from "../assets/images/consultivo-conheca.png";
import imageComplianceConheca from "../assets/images/compliance-conheca.png";
import imageDataAnalyticsConheca from "../assets/images/data-analytics-conheca.png";

export const Versao = 'beta';

export const Alerta = {
  Nivel: {
    NA: { id: 1, label: 'N/A' },
    Aviso: { id: 2, label: 'Aviso' },
    Erro: { id: 3, label: 'Erro' },
  },
};

export const Email = {
  Estado: {
    Pendente: { id: 1, label: 'Pendente' },
    Enviado: { id: 2, label: 'Enviado' },
    Erro: { id: 3, label: 'Erro' },
  },
};

export const Empresa = {
  Tipo: {
    SociedadeLimitada: { id: 1, label: 'Sociedade Limitada' },
    SociedadePorAcoes: { id: 2, label: 'Sociedade por Ações' },
    Eireli: { id: 3, label: 'Eireli' },
    SociedadeSimples: { id: 4, label: 'Sociedade Simples' },
    EmpresarioIndividual: { id: 5, label: 'Empresario Individual' },
    Associacao: { id: 6, label: 'Associação' },
    Fundacao: { id: 7, label: 'Fundação' },
    SociedadeEmContaDeParticipacao: { id: 8, label: 'Sociedade em Conta de Participação' },
    Cooperativa: { id: 9, label: 'Cooperativa' },
    FundoDeInvestimento: { id: 10, label: 'Fundo de Investimento' },
    EntidadeEstrangeira: { id: 11, label: 'Entidade Estrangeira' },
  },
};

export const EmpresaAdministrador = {
  Tipo: {
    Administrador: { id: 1, label: 'Administrador' },
    Diretoria: { id: 2, label: 'Diretoria' },
    ConselhoConsultivo: { id: 3, label: 'Conselho Consultivo' },
    ConselhoDeAdministracao: { id: 4, label: 'Conselho de Administração' },
    ConselhoFiscal: { id: 5, label: 'Conselho Fiscal' },
    Outros: { id: 6, label: 'Outros' },
  },
};

export const EmpresaBeneficiarioEmpresa = {
  Apresentacao: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
    Nao: { id: 3, label: 'Não' },
  },

  Aceite: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
  },
};

export const EmpresaBeneficiarioSocio = {
  Apresentacao: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
    Nao: { id: 3, label: 'Não' },
  },

  Aceite: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
  },
};

export const EmpresaConta = {
  Publicacao: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
    Nao: { id: 3, label: 'Não' },
    NaoSeAplica: { id: 4, label: 'N/A' },
  },

  Aprovacao: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
    Nao: { id: 3, label: 'Não' },
    NaoSeAplica: { id: 4, label: 'N/A' },
  },

  Registro: {
    Pendente: { id: 1, label: 'Pendente' },
    Sim: { id: 2, label: 'Sim' },
    NaoSeAplica: { id: 4, label: 'N/A' },
  },

  Status: {
    Pendente: { id: 1, label: 'Pendente' },
    Publicada: { id: 2, label: 'Publicada' },
    Aprovada: { id: 3, label: 'Aprovada' },
    Registrada: { id: 4, label: 'Registrada' },
  },
};

export const EmpresaProcurador = {
  Tipo: {
    Publica: { id: 1, label: 'Pública' },
    Particular: { id: 2, label: 'Particular' },
    Eletronica: { id: 3, label: 'Eletrônica' },
  },
};

export const EmpresaUnidade = {
  Tipo: {
    Matriz: { id: 1, label: 'Matriz' },
    Filial: { id: 2, label: 'Filial' },
  },
};

export const EmpresaLicenca = {
  Aviso: {
    Sim180Dias: { id: 180, label: 'Avisar 180 dias antes' },
    Sim150Dias: { id: 150, label: 'Avisar 150 dias antes' },
    Sim120Dias: { id: 120, label: 'Avisar 120 dias antes' },
    Sim90Dias: { id: 90, label: 'Avisar 90 dias antes' },
    Sim60Dias: { id: 60, label: 'Avisar 60 dias antes' },
    Sim30Dias: { id: 30, label: 'Avisar 30 dias antes' },
    Nao: { id: -1, label: 'Não' },
  }
}

export const EmpresaTabs = [
  { name: 'cadastro', label: 'Cadastro', shortLabel: 'Cadastro', modulos: ['societario', 'regulatorio', 'workflow', 'consultivo', 'compliance', 'data-analytics'] },
  { name: 'unidades', label: 'Unidades', shortLabel: 'Unidades', modulos: ['societario', 'regulatorio', 'workflow', 'consultivo', 'compliance', 'data-analytics'] },
  { name: 'certificados', label: 'Certificados', shortLabel: 'Certificados', modulos: ['societario', 'regulatorio', 'workflow', 'consultivo', 'compliance', 'data-analytics'] },
  { name: 'licencas', label: 'Licenças', shortLabel: 'Licenças', modulos: ['regulatorio'] },
  { name: 'contratos', label: 'Atos Societários', shortLabel: 'Atos Societ.', modulos: ['societario'] },
  { name: 'livros-digitais', label: 'Livros Digitais', shortLabel: 'Livros Digitais', modulos: ['societario'] },
  { name: 'socios', label: 'Sócios', shortLabel: 'Sócios', modulos: ['societario'] },
  { name: 'administradores', label: 'Administradores', shortLabel: 'Admin.', modulos: ['societario'] },
  { name: 'procuradores', label: 'Procuradores', shortLabel: 'Procur.', modulos: ['societario'] },
  { name: 'representantes', label: 'Representantes Legais', shortLabel: 'Repres. Legais', modulos: ['societario'] },
  { name: 'beneficiarios-empresa', label: 'Beneficiários Finais da Empresa', shortLabel: 'Benef. Finais', modulos: ['societario'] },
  { name: 'beneficiarios-socio', label: 'Beneficiários Finais do Sócio', shortLabel: 'Benef. Sócio', modulos: ['societario'] },
  { name: 'pls', label: 'Demonstrações Financeiras', shortLabel: 'Dem. Financ.', modulos: ['societario'] },
  { name: 'contas', label: 'Aprovação de Contas Anual', shortLabel: 'Aprovação Contas', modulos: ['societario'] },
  // {name: 'cces', label: 'Censo de Capital Estrangeiro no País', shortLabel: 'CCE, modulos: ['societario']'},
  { name: 'rde-ieds', label: 'Operações de Câmbio para Investimento Estrangeiro Direto', shortLabel: 'Câmbios', modulos: ['societario'] },
  { name: 'defs', label: 'Declarações Bacen', shortLabel: 'Declar. Bacen', modulos: ['societario'] },
  { name: 'cbes', label: 'Capital Brasileiro no Exterior', shortLabel: 'CBE', modulos: ['societario'] },
];

export const EmpresaTabByName = EmpresaTabs.reduce((pv, cv) => {
  pv[cv.name] = cv;
  return pv;
}, {});

export const Endereco = {
  Estado: {
    AC: { id: 'AC', label: 'Acre' },
    AL: { id: 'AL', label: 'Alagoas' },
    AP: { id: 'AP', label: 'Amapá' },
    AM: { id: 'AM', label: 'Amazonas' },
    BA: { id: 'BA', label: 'Bahia' },
    CE: { id: 'CE', label: 'Ceará' },
    ES: { id: 'ES', label: 'Espírito Santo' },
    GO: { id: 'GO', label: 'Goiás' },
    MA: { id: 'MA', label: 'Maranhão' },
    MT: { id: 'MT', label: 'Mato Grosso' },
    MS: { id: 'MS', label: 'Mato Grosso do Sul' },
    MG: { id: 'MG', label: 'Minas Gerais' },
    PA: { id: 'PA', label: 'Pará' },
    PB: { id: 'PB', label: 'Paraíba' },
    PR: { id: 'PR', label: 'Paraná' },
    PE: { id: 'PE', label: 'Pernambuco' },
    PI: { id: 'PI', label: 'Piauí' },
    RJ: { id: 'RJ', label: 'Rio de Janeiro' },
    RN: { id: 'RN', label: 'Rio Grande do Norte' },
    RS: { id: 'RS', label: 'Rio Grande do Sul' },
    RO: { id: 'RO', label: 'Rondônia' },
    RR: { id: 'RR', label: 'Roraima' },
    SC: { id: 'SC', label: 'Santa Catarina' },
    SP: { id: 'SP', label: 'São Paulo' },
    SE: { id: 'SE', label: 'Sergipe' },
    TO: { id: 'TO', label: 'Tocantins' },
    DF: { id: 'DF', label: 'Distrito Federal' },
  },
};

export const Modulos = [
  { id: 'societario', label: 'Societário', image: imageSocietario, imageConheca: imageSocietarioConheca, location: '/societario/dashboard' },
  { id: 'regulatorio', label: 'Regulatório', image: imageRegulatorio, imageConheca: imageRegulatorioConheca, location: '/regulatorio/dashboard' },
  { id: 'compliance', label: 'Compliance', image: imageCompliance, imageConheca: imageComplianceConheca, location: '/compliance/dashboard' },
  { id: 'consultivo', label: 'Consultivo', image: imageConsultivo, imageConheca: imageConsultivoConheca, location: '/consultivo/dashboard' },
  { id: 'workflow', label: 'Workflow', image: imageWorkflow, imageConheca: imageWorkflowConheca, location: '/workflows' },
  { id: 'data-analytics', label: 'Data Analytics', image: imageDataAnalytics, imageConheca: imageDataAnalyticsConheca, location: '/data-analytics/dashboard' },
]

export const Usuario = {
  NivelAcesso: {
    Cliente: { id: 0, label: 'Cliente' },
    ClienteOperador: { id: 1, label: 'Cliente Operador' },
    ClienteGestor: { id: 2, label: 'Cliente Gestor' },
    Operador: { id: 3, label: 'Operador' },
    Gestor: { id: 4, label: 'Gestor' },
    Diretor: { id: 5, label: 'Diretor' },
    SuperUsuario: { id: 7, label: 'Super Usuário' },
  },
};

export const Consulta = {
  Prioridade: {
    Alta: { id: 1, label: 'Alta' },
    Media: { id: 2, label: 'Média' },
    Baixa: { id: 3, label: 'Baixa' },
  },
  Status: {
    Cadastro: { id: 1, label: 'Cadastro' },
    Aberto: { id: 2, label: 'Aberto' },
    EmTrabalho: { id: 3, label: 'Em Trabalho' },
    AguardandoDocumentos: { id: 4, label: 'Aguardando Documentos' },
    Pausado: { id: 5, label: 'Pausado' },
    Concluido: { id: 6, label: 'Concluído' },
    Cancelado: { id: 7, label: 'Cancelado' },
  },
  Permissoes: {
    CONSULTA_DETALHAR: { id: 'CONSULTA_DETALHAR', label: 'CONSULTA_DETALHAR' },
    CADASTRO_DETALHAR: { id: 'CADASTRO_DETALHAR', label: 'CADASTRO_DETALHAR' },
    RESPOSTA_DETALHAR: { id: 'RESPOSTA_DETALHAR', label: 'RESPOSTA_DETALHAR' },
    ANEXO_DETALHAR: { id: 'ANEXO_DETALHAR', label: 'ANEXO_DETALHAR' },
    FASE_DETALHAR: { id: 'FASE_DETALHAR', label: 'FASE_DETALHAR' },
    LOG_DETALHAR: { id: 'LOG_DETALHAR', label: 'LOG_DETALHAR' },
    CABECALHO_ALTERAR: { id: 'CABECALHO_ALTERAR', label: 'CABECALHO_ALTERAR' },
    CADASTRO_ALTERAR: { id: 'CADASTRO_ALTERAR', label: 'CADASTRO_ALTERAR' },
    RESPOSTA_ALTERAR: { id: 'RESPOSTA_ALTERAR', label: 'RESPOSTA_ALTERAR' },
    ANEXO_ALTERAR: { id: 'ANEXO_ALTERAR', label: 'ANEXO_ALTERAR' },
    FASE_ALTERAR: { id: 'FASE_ALTERAR', label: 'FASE_ALTERAR' },
    LOG_ALTERAR: { id: 'LOG_ALTERAR', label: 'LOG_ALTERAR' },
    CONSULTA_EXCLUIR: { id: 'CONSULTA_EXCLUIR', label: 'CONSULTA_EXCLUIR' },
  }
}

export function findById(obj, id) {
  for (const v of Object.values(obj)) {
    if (v.id === id)
      return v;
  }
  return null;
}

export function findByIdAndMap(obj, id, mapper) {
  const o = findById(obj, id);
  if (o)
    return mapper(o);
  return null;
}
