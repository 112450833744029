import { Spin } from 'antd';
import React from 'react';
import { Chart } from 'react-chartjs-2';
import FlexCard from '../../../components/flex-card';
import useFormatter from "../../../hooks/useFormatter";
import styles from './_styles.less'

export default function StatusCard({ className, count, loading, onClick, title, total }) {
  const { formatNumber } = useFormatter();
  const opacity = count ? 1 : 0.2;

  const percent = total > 0 ?
    formatNumber(100 * count / total, { maximumFractionDigits: 0 }) + '%' :
    '-';

  const data = {
    labels: [title, ''],
    datasets: [
      {
        data: [count, total - count],
        backgroundColor: ['rgb(255, 255, 255)', 'rgba(255, 255, 255, 0.65)'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    animation: loading ? false : undefined,
    cutout: '90%',
    hover: false,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: false,
      tooltip: false,
    },
  };

  return (
    <Spin spinning={loading}>
      <FlexCard
        bordered={false}
        className={className}
        hoverable
        onClick={onClick}
        style={{ opacity }}
      >
        <div className={styles['status-card']}>
          <div className={styles['header']}>
            <div className={styles['title']}>{title}</div>
            <div className={styles['count']}>{formatNumber(count)}</div>
          </div>
          <div className={styles['chart']}>
            <Chart type="doughnut" data={data} options={options}/>
            <span className={styles['percent']}>{percent}</span>
          </div>
        </div>
      </FlexCard>
    </Spin>);
}
