import React, { useEffect } from 'react';
import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import { useSelector } from "react-redux";
import ConsultivoMenu from "../../../components/consultivo-menu";
import { Col, Row } from "antd";
import { fetchConsultas, fetchConsultasPorStatus, filtrar, filtroSelector, resetar } from "../../../store/consultivoDashboard";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import ConsultasCard from "./_ConsultasCard";
import { acessoSelector } from "../../../store/acessos";
import { fetchConsultivo } from "../../../store/consultivo";
import ConsultasPorStatusCardGroup from "./_ConsultasPorStatusCardGroup";
import FiltroStatusCard from "./_FiltroStatusCard";
import FiltroAreaCard from "./_FiltroAreaCard";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { isGestorSelector } from "../../../store/autenticacao";

export default function ConsultivoDashboardPage() {
  const dispatch = useDispatchWithResult();
  const isGestor = useSelector(isGestorSelector);
  const filtro = useSelector(filtroSelector);
  const acesso = useSelector(acessoSelector);
  const acessoId = acesso?.id ?? 0;

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
    dispatch(filtrar({ acessoId }));
  }, [acessoId]);

  useDebouncedEffect(500, () => {
    dispatch(fetchConsultasPorStatus());
    dispatch(fetchConsultas());
  }, [filtro])

  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/dashboard"/>
          <ConsultivoMenu showCreate={isGestor}/>
        </>
      }
    >
      <Row gutter={[16, 16]}>
        <ConsultasPorStatusCardGroup/>
        <Col span={24} md={16}>
          <ConsultasCard/>
        </Col>
        <Col span={24} md={8}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FiltroStatusCard/>
            </Col>
            <Col span={24}>
              <FiltroAreaCard/>
            </Col>
          </Row>
        </Col>
      </Row>
    </AuthenticatedLayout>);
}
