import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Consulta } from "../constants";

const initialState = {
  filtro: {
    acessoId: null,
    statusIds: [
      Consulta.Status.Cadastro.id,
      Consulta.Status.Aberto.id,
      Consulta.Status.EmTrabalho.id,
      Consulta.Status.AguardandoDocumentos.id,
      Consulta.Status.Pausado.id,
    ],
    areaIds: []
  },

  consultasPorStatus: {},
  consultas: [],
};

export const filtroSelector = state => state.consultivoDashboard.filtro;
export const consultasPorStatusSelector = state => state.consultivoDashboard.consultasPorStatus;
export const consultasSelector = state => state.consultivoDashboard.consultas;

export const fetchConsultasPorStatus = createAsyncThunk(
  'consultivoDashboard/fetchConsultasPorStatus',
  async (_, thunkAPI) => {
    try {
      const filtro = filtroSelector(thunkAPI.getState());
      const { data } = await axios.get('/consultivo/dashboard/consultas-por-status', { params: filtro });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchConsultas = createAsyncThunk(
  'consultivoDashboard/fetchConsultas',
  async (_, thunkAPI) => {
    try {
      const filtro = filtroSelector(thunkAPI.getState());
      const { data } = await axios.get('/consultivo/dashboard/consultas', { params: filtro });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'consultivoDashboard',
  initialState,
  reducers: {
    resetar: (state) => {
      return initialState;
    },
    filtrar: (state, action) => {
      state.filtro = { ...state.filtro, ...action.payload };
    }
  },
  extraReducers: {
    [fetchConsultasPorStatus.fulfilled]: (state, action) => {
      state.consultasPorStatus = action.payload;
    },
    [fetchConsultas.fulfilled]: (state, action) => {
      state.consultas = action.payload;
    }
  },
});

export default slice.reducer;

export const { resetar, filtrar } = slice.actions;
