import {Spin} from 'antd';
import React, {useRef} from 'react';
import {Chart, getElementAtEvent} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import FlexCard from '../../../components/flex-card';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import {comparativoEmpresasSelector} from '../../../store/societarioDashboard';
import {fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas} from '../../../store/empresas';
import {openPopupEmpresa} from '../../../util/window';
import styles from './_styles.less';

export default function ComparativoEmpresasCard() {
  const chartRef = useRef(null);
  const {t} = useTranslation();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const comparativoEmpresas = useSelector(comparativoEmpresasSelector);

  const labels = [];
  const datas = [[], [], []];

  const empresas = [...comparativoEmpresas].sort((a, b) => {
    const cs = [b.sucessos - a.sucessos, a.erros - b.erros];
    for (let c of cs) {
      if (c !== 0)
        return c;
    }
    return 0;
  });
  empresas
      .forEach(empresa => {
        labels.push(empresa.apelido || empresa.razaoSocial);
        datas[0].push(empresa.erros);
        datas[1].push(empresa.avisos);
        datas[2].push(empresa.sucessos);
      });

  const data = {
    labels,
    datasets: [
      {label: t('Pendências'), data: datas[0], backgroundColor: '#ff4d4f'},
      {label: t('Alertas'), data: datas[1], backgroundColor: '#faad14'},
      {label: t('Em dia'), data: datas[2], backgroundColor: '#009edb'},
    ],
  };

  const options = {
    animation: loading ? false : undefined,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        reverse: true,
        labels: {
          boxHeight: 8,
          boxWidth: 16,
        },
      },
    },
    scales: {
      xAxes: {
        stacked: true,
        ticks: {
          callback(value, index, values) {
            let label = this.getLabelForValue(value);
            if (label?.length > 7)
              label = label.substr(0, 5) + '..';
            return label;
          },
          font: {
            size: 9,
          },
        },
      },
      yAxes: {
        stacked: true,
      },
    },
  };

  const onUnload = () => {
    dispatch(fetchEmpresas({acessoId: acesso?.id}));
  };

  const handleClick = e => {
    const {current: chart} = chartRef;
    if (chart) {
      const element = getElementAtEvent(chart, e);
      if (element.length) {
        const empresa = empresas[element[0].index];
        openPopupEmpresa(empresa, 'cadastro', {onUnload});
      }
    }
  };

  return (
      <Spin spinning={loading}>
        <FlexCard bordered={false}>
          <div className={styles['comparativo-empresas']}>
            <h1>{t('Comparativo entre empresas')}</h1>
            <Chart ref={chartRef} type="bar" data={data} options={options} onClick={handleClick} />
          </div>
        </FlexCard>
      </Spin>);
}
