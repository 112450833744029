import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';
import SelectBoolean from '../../../../components/select-boolean';
import InputName from "../../../../components/input-name";
import InputNumber from "../../../../components/input-number";
import SelectEmpresaLicencaAviso from "../../../../components/select-empresa-licenca-aviso";
import SelectEmpresaSocio from "../../../../components/select-empresa-socio";
import SelectEmpresaUnidade from "../../../../components/select-empresa-unidade";

export default function EmpresaLicencaForm({ disabled, empresaId, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item name="unidadeId" label={t('Unidade')}>
            <SelectEmpresaUnidade disabled={disabled} empresaId={empresaId} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="nome" label={t('Nome')}>
            <InputName disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="orgaoEmissor" label={t('Órgão Emissor')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="custoTotal" label={t('Custo Total (R$)')}>
            <InputNumber disabled={loading} maximumFractionDigits={2} minimumFractionDigits={2}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="dataInicial" label={t('Data Inicial')}>
            <InputDate disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="indeterminado" label={t('Prazo Indeterminado')}>
            <SelectBoolean disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.indeterminado !== nextValues.indeterminado}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const indeterminado = getFieldValue('indeterminado');
              if (indeterminado === false)
                return (
                  <Form.Item name="dataFinal" label={t('Data Final')}>
                    <InputDate disabled={disabled}/>
                  </Form.Item>);
              setFieldsValue({ 'dataFinal': null });
              return null;
            }}
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="aviso" label={t('Controle de Prazo')}>
            <SelectEmpresaLicencaAviso disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="site" label={t('Site')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="login" label={t('Login')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="senha" label={t('Senha')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="conteudo" label={t('Conteúdo')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexo" label={t('Anexo')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexoComprovantePagamento" label={t('Comprovante de Pagamento')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

EmpresaLicencaForm.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
