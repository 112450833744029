import { Form, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import ActionButton from '../../../../components/action-button';
import MiniFormDrawer from '../../../../components/mini-form-drawer';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../hooks/useLoading';
import { cabecalhoSelector, fetchCabecalho, fetchCadastro, fetchFases, fetchLogs, fetchPermissoes } from '../../../../store/consultivoConsulta';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import SelectConsultivoConsultaStatus from "../../../../components/select-consultivo-consulta-status";

export default function CabecalhoDrawer({ onClose, visible }) {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const cabecalho = useSelector(cabecalhoSelector);
  const loading = useLoading(fetchCabecalho.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const match = useRouteMatch();
  const consultaId = match.params.id;

  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    form.setFieldsValue(cabecalho);
  }, [cabecalho]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#status').focus();
  };

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/cabecalho`, values), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        dispatch(fetchPermissoes({ consultaId }));
        dispatch(fetchCabecalho({ consultaId }));
        dispatch(fetchCadastro({ consultaId }));
        dispatch(fetchFases({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
        fireClose();
      })
      .finally(() => setLoadingSave(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        onClick={() => form.submit()}
        loading={loadingSave}
      />
      <ActionButton.Close
        onClick={fireClose}
        disabled={loadingSave}
      />
    </>
  );

  const drawerTitle = t('Alterar Cabecalho');

  return (
    <MiniFormDrawer
      drawerTitle={drawerTitle}
      drawerFooter={footer}
      drawerWidth={400}
      drawerVisible={visible}
      form={form}
      loading={loading}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onFinish={handleFinish}
    >
      <Form.Item name="status" label={t('Status')}>
        <SelectConsultivoConsultaStatus disabled={loading}/>
      </Form.Item>
    </MiniFormDrawer>
  );
}

CabecalhoDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};
