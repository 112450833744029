import { message } from 'antd';

export default function unwrapAxiosPromise(promise, form) {
  if (typeof form === 'object')
    form.setFields(form.getFieldsError().map(({ name }) => ({ name, errors: [] })));
  return promise
    .then(res => res?.data ?? res)
    .catch(err => {
      message.error(err.mensagem);
      if ((typeof form === 'object') && (typeof err.erros === 'object')) {
        form.setFields(Object.entries(err.erros)
          .map(([name, errors]) => {
            if (name.startsWith('['))
              name = JSON.parse(name);
            return ({ name, errors });
          }));
      }
      return Promise.reject(err);
    });
}
