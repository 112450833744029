import {Breadcrumb} from 'antd';
import axios from 'axios';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ActionBar from '../../components/action-bar';
import FlexCard from '../../components/flex-card';
import MiniTable from '../../components/mini-table';
import AuthenticatedLayout from '../../layouts/authenticated';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import EmailDrawer from './_EmailDrawer';
import EmailsTable from './_EmailsTable';

export default function EmailsPage() {
  const miniTableRef = useRef();
  const {t} = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [activeSearch, setActiveSearch] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [email, setEmail] = useState();
  const [emailDrawerVisible, setEmailDrawerVisible] = useState(false);

  const handleSearch = search => {
    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get('/emails', {params: {search}}))
        .then(data => {
          setActiveSearch(search);
          setDataSource(data);
        })
        .finally(() => setLoadingSearch(false));
  };

  const handleClick = email => {
    setEmailDrawerVisible(true);
    setLoadingEmail(true);
    unwrapAxiosPromise(axios.get(`/emails/${email.id}`))
        .then(email => {
          setEmail(email);
        })
        .finally(() => setLoadingEmail(false));
  };

  const handleEmailDrawerClose = () => {
    setEmailDrawerVisible(false);
  };

  const handleEmailDrawerAfterClose = () => {
    setEmail(undefined);
  };

  const title = () => {
    return (
        <ActionBar onSearch={handleSearch}>
          <MiniTable.DownloadButton disabled={loadingSearch || loadingRemove || dataSource.length === 0} miniTableRef={miniTableRef}/>
        </ActionBar>);
  };

  return (
      <AuthenticatedLayout>
        <Breadcrumb>
          <Breadcrumb.Item>{t('Histórico de E-mails')}</Breadcrumb.Item>
        </Breadcrumb>
        <FlexCard>
          <EmailsTable
              dataSource={dataSource}
              loading={loadingSearch || loadingRemove}
              miniTableRef={miniTableRef}
              onClick={handleClick}
              title={title}
          />
        </FlexCard>
        <EmailDrawer
            value={email}
            visible={emailDrawerVisible}
            loading={loadingEmail}
            onClose={handleEmailDrawerClose}
            onAfterClose={handleEmailDrawerAfterClose}
        />
      </AuthenticatedLayout>
  );
}
