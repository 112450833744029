import React, { useEffect } from 'react';
import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import { useSelector } from "react-redux";
import { isGestorSelector } from "../../../store/autenticacao";
import ConsultivoMenu from "../../../components/consultivo-menu";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { acessoSelector } from "../../../store/acessos";
import { fetchConsultivo } from "../../../store/consultivo";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { fetchConsultasPorStatus, filtrar, filtroSelector, resetar } from "../../../store/consultivoQuadro";
import FiltroForm from "./_FiltroForm";
import ConsultasPorStatusCollection from "./_ConsultasPorStatusCollection";

export default function ConsultivoQuadroPage() {
  const dispatch = useDispatchWithResult();
  const isGestor = useSelector(isGestorSelector);
  const filtro = useSelector(filtroSelector);
  const acesso = useSelector(acessoSelector);
  const acessoId = acesso?.id ?? 0;

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
    dispatch(filtrar({ acessoId }));
  }, [acessoId]);

  useDebouncedEffect(500, () => {
    dispatch(fetchConsultasPorStatus());
  }, [filtro])

  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/quadro"/>
          <ConsultivoMenu showCreate={isGestor}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      <FiltroForm/>
      <ConsultasPorStatusCollection/>
    </AuthenticatedLayout>);
}
