import { Checkbox, Col, Row, Spin } from "antd";
import FlexCard from "../../../components/flex-card";
import styles from "./_styles.less";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { filtrar, filtroSelector } from "../../../store/consultivoDashboard";
import useConsultivo from "../../../hooks/useConsultivo";
import SelectConsultivoArea from "../../../components/select-consultivo-area";

export default function FiltroAreaCard({}) {
  const { t } = useTranslation();
  const { getArea, getAreaCaminho } = useConsultivo();
  const dispatch = useDispatchWithResult();
  const { areaIds } = useSelector(filtroSelector);

  const options = useMemo(() =>
      areaIds
        .map(getArea)
        .map(area => ({
          label: (
            <div className={styles['option-node']}>
              <div className={styles['icon']} style={{ backgroundColor: '#' + area.cor }}/>
              <div>{getAreaCaminho(area.id)}</div>
            </div>
          ),
          value: area.id
        })),
    [getArea, getAreaCaminho, areaIds]);

  const handleAdd = useCallback(areaId => {
    if (areaIds.includes(areaId))
      return;
    dispatch(filtrar({ areaIds: [...areaIds, areaId] }));
  }, [areaIds]);

  const handleChange = useCallback(areaIds => dispatch(filtrar({ areaIds })), [areaIds]);

  return (
    <Spin spinning={false}>
      <FlexCard bordered={false}>
        <div className={styles['filtro-area']}>
          <h1>{t('Área de Atuação')}</h1>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <SelectConsultivoArea
                className={styles['select']}
                value={null}
                onChange={handleAdd}
                filter={area => !areaIds.includes(area.id)}
              />
            </Col>
            <Col span={24}>
              <Checkbox.Group
                className={styles['checkbox-vertical']}
                options={options}
                value={areaIds}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
      </FlexCard>
    </Spin>
  );
}
