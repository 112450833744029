import { Col, Row, Spin } from "antd";
import React from 'react';
import ActionBar from "../../../../components/action-bar";
import ActionButton from "../../../../components/action-button";
import { useSelector } from "react-redux";
import { fetchResposta, hasPermissaoSelector, respostaSelector } from "../../../../store/consultivoConsulta";
import useLoading from "../../../../hooks/useLoading";
import { useHistory, useRouteMatch } from "react-router";
import TextItem from "../../../../components/text-item";
import { useTranslation } from "react-i18next";
import { Consulta } from "../../../../constants";

export default function ConsultivoConsultaRespostaDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(hasPermissaoSelector(Consulta.Permissoes.RESPOSTA_ALTERAR.id));
  const resposta = useSelector(respostaSelector);
  const loading = useLoading(fetchResposta.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;

  const handleEdit = () => {
    history.replace(`/consultivo/consultas/${consultaId}/resposta/edit`);
  };

  return (
    <Spin spinning={loading}>
      {isEditable &&
        <ActionBar>
          <ActionButton.Edit onClick={handleEdit}/>
        </ActionBar>
      }
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Resposta')}>{resposta?.resposta}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Tags')}>{resposta?.tags?.join(', ')}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Permissões')}>{resposta?.permissoes}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Projeto')}>{resposta?.projeto?.titulo}</TextItem></Col>
        <Col flex="400px"><TextItem label={t('Consulta Origem')}>{resposta?.consultaOrigem?.titulo}</TextItem></Col>
      </Row>
    </Spin>
  )
}
