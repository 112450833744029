import { Tooltip } from "antd";
import React from "react";
import styles from './_styles.less'

export default function TableCellEllipsis({ text, maxLength = 100 }) {
  if (text?.length > maxLength) {
    return (
      <Tooltip title={text} overlayClassName={`${styles['tooltip-overlay']} ws-pre-line`}>
        <span className="ws-pre-line">{text.slice(0, maxLength - 3) + '...'}</span>
      </Tooltip>
    )
  }

  return (<span className="ws-pre-line">{text}</span>)
}